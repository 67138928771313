import React from 'react';
import Layout from '@components/common/Layout';
import Seo from '@components/common/Seo';
import ScreeningTest from '@components/screeningTest/ScreeningTest';
import { resultSettings, riskSettings, questions, references } from '@quizData/fr/fr/shoulder';
import { MARKET_FR } from '@consts/market';
import { JOINT_SHOULDER } from '@consts/variant';
import { getScreeningPage } from '@/src/common/helpers/tracking';

const IndexPage = () => (
  <Layout
    language="fr"
    segmentId={process.env.GATSBY_SEGMENT_SCREENING_2_PRODUCTION_SE as string}
    page={getScreeningPage(questions)}
  >
    <Seo
      title="Screening - Épaule"
      description="Screening - Épaule"
      lang="fr"
    />

    <section data-theme="white">
      <ScreeningTest
        variant={JOINT_SHOULDER}
        market={MARKET_FR}
        resultSettings={resultSettings}
        riskSettings={riskSettings}
        questions={questions}
        references={references}
        downloadUrl="https://app.adjust.com/2zpe76o"
      />
    </section>
  </Layout>
);

export default IndexPage;
